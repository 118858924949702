<template>
  <div>
    <!-- table -->
    <b-table
      :current-page="currentPage"
      :per-page="pageLength"
      :total-rows="count"
      :items="invoices"
      :fields="tableFields"
      striped
      hover
      responsive
      show-empty
      @filtered="onFiltered"
    >
      <template #cell(id)="data">
        {{ data.item.id }}
      </template>

      <template #cell(action)="data">
        <b-button
          variant="primary"
          size="sm"
          :to="{ name: 'invoice-show-direx', params: { id: data.item.id } }"
        >
          Voir Facture
        </b-button>
      </template>

      <template #cell(start_date)="data">
        {{ formatDate(data.item.start_date) }}
      </template>

      <template #cell(end_date)="data">
        {{ formatDate(data.item.end_date) }}
      </template>

      <template #cell(created_at)="data">
        {{ formatDateTime(data.item.created_at) }}
      </template>

      <template #cell(number_of_orders)="data">
        {{ data.item.number_of_orders }}
      </template>

      <template #cell(total_amount)="data">
        {{ formatCurrency(data.item.total_amount) }}
      </template>

      <template #cell(status)="data">
        <b-badge :variant="getStatusVariant(data.item.status)">
          {{ data.item.status_display }}
        </b-badge>
      </template>

      <template #cell(order_details)="data">
        <b-button
          variant="primary"
          size="sm"
          @click="showOrderDetails(data.item)"
        >
          Voir détaille
        </b-button>
      </template>
    </b-table>

    <b-modal
      v-model="showModal"
      title="Order Details"
      hide-footer
    >
      <div
        v-for="order in selectedOrder.order_details"
        :key="order.id"
      >
        <p>BL: {{ order.id }}</p>
        <p>Livré le: {{ formatDate(order.delivered_at) }}</p>
        <p>Prix TTC: {{ formatCurrency(order.price_ttc) }}</p>
        <hr>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BModal, BBadge, BButton,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BTable,
    BModal,
    BBadge,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      pageLength: 10,
      count: 0,
      invoices: [],
      tableFields: [
        { key: 'id', label: 'BL' },
        { key: 'start_date', label: 'Date Début' },
        { key: 'end_date', label: 'Date Fin' },
        { key: 'created_at', label: 'Crée le' },
        { key: 'number_of_orders', label: 'Nombre de colis' },
        { key: 'total_amount', label: 'Net A payer' },
        { key: 'status', label: 'Status' },
        { key: 'order_details', label: 'Détaille colis' },
        { key: 'action', label: 'Action' },
      ],
      showModal: false,
      selectedOrder: {},
    }
  },
  created() {
    this.getInvoices()
  },
  methods: {
    async getInvoices() {
      const { data } = await axios.get('/api/invoices/invoice-providers')
      this.invoices = data
      this.count = data.length
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString)
      return `${this.formatDate(dateTimeString)} ${dateTime.toLocaleTimeString()}`
    },
    formatCurrency(amount) {
      return `${amount} TND`
    },
    getStatusVariant(status) {
      switch (status) {
        case 'unpaid':
          return 'danger'
        case 'paid':
          return 'success'
        default:
          return 'secondary'
      }
    },
    showOrderDetails(order) {
      this.selectedOrder = order
      this.showModal = true
    },
    onFiltered(filteredItems) {
      this.count = filteredItems.length
    },
  },
}
</script>
